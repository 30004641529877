import * as React from "react"
import Layout from "../components/layout"
import * as animationData from '../mail_lottie.json'
import Lottie from 'react-lottie';
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    textAlign: 'center'
  },
  divLottie: {
    padding: 25,
    marginBottom: 60
  }
}))

const SuccessMessage = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const classes = useStyles()

  return <Layout>
    <h1 className={classes.title} style={{marginTop: 120,}}>
      Sikeres kapcsolatfelvétel!
    </h1>
    <h3 className={classes.title}>
      A kapcsolatot felvesszük veled 72 órán belül
    </h3>
    <div className={classes.divLottie}>
      <Lottie options={defaultOptions}
              height={400}
              width={400}
              isStopped={false}
              isPaused={false}/>
    </div>
  </Layout>
}

export default SuccessMessage
